import React, { useEffect, useState, useContext, ChangeEvent } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { MembershipAccreditationType } from '../types';
import { MembershipFormContextProps } from '../index';
import { getMembershipByType } from '../services/membership';
import SignUpFormSection from './SignUpFormSection';
import Field from '../../Core/Form/Field';
import MembershipLink from './MembershipLink';
import Spinner from '../../Core/components/Spinner';
import { FormContext } from './SignUpForm';
import { appSettings } from '../../AppSettings';
import { Language } from '../../Core/i18n/types';

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import SignUpFormFooter from './SignUpFormFooter';

type Props = {
    accreditationTypes: MembershipAccreditationType[];
};

const SignUpFormAccreditationTypes: React.FC<Props> = ({
    accreditationTypes,
}) => {
    const {
        currentSection,
        currentPrice,
        setCurrentPrice,
        values,
        setValues,
        currentAccreditationType,
        setCurrentAccreditationType,
        membershipForm,
        setSectionsNumber,
    } = useContext(FormContext);

    const url = useLocation();

    const lang = appSettings.language as Language;

    const [lastTicketCount, setLastTicketCount] = useState<number>(1);
    const onAccreditationTypeChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (setCurrentPrice) {
            setCurrentPrice(Number(e.target.value));
        }
        if (setLastTicketCount) {
            setLastTicketCount(1);
        }

        if (setValues) {
            setValues({
                ...values,
                Bag: 0,
                BagCount: 1,
                Cup: 0,
                CupCount: 1,
                Booklet: 0,
                Lanyard: 0,
                Stickers: 0,
                Bookmark: 0,
                Dice: 0,
                Anonymous: 0,
                ticketCount: 1,
                Merch: [],
            });
        }

        if (setCurrentAccreditationType) {
            setCurrentAccreditationType(e.target.id);
        }

        const field = 'Email';

        const newSectionsNumber =
            membershipForm?.membershipAccreditationTypes.find(
                (mat) => mat.name === e.target.id,
            )?.sections.length;

        if (setSectionsNumber && newSectionsNumber) {
            setSectionsNumber(newSectionsNumber);
        }

        //TODO errors
        // const newErrors = { ...errors, [field]: [] };
        // setErrors(newErrors);
    };

    const onTicketCountChange = (e: ChangeEvent<HTMLInputElement>) => {
        let value = e.target.value;

        if (!value) {
            value = '1';
        } else {
            value = value.substring(value.length - 1, value.length);
        }

        if (Number(value) < 1) {
            value = '1';
        }

        if (Number(value) > 5) {
            value = '5';
        }

        const newTicketCount = Number(value);

        if (setValues) {
            setValues({
                ...values,
                [e.target.name]: newTicketCount,
            });
        }

        let priceWithNoTickets =
            currentPrice - lastTicketCount * calculatePrice();
        const newPrice = priceWithNoTickets + newTicketCount * calculatePrice();

        if (setCurrentPrice) {
            setCurrentPrice(newPrice);
        }
        setLastTicketCount(newTicketCount);
    };

    const calculatePrice = (): number => {
        switch (currentAccreditationType) {
            case 'Full-regular':
                return lang === 'pl' ? 269 : 60;
            case 'Friday':
                return lang === 'pl' ? 159 : 36;
            case 'Saturday':
                return lang === 'pl' ? 159 : 36;
        }

        return 0;
    };

    return (
        <div
            style={{
                display: currentSection === 0 ? 'block' : 'none',
            }}
        >
            {!url.pathname.includes('program') &&
                !url.pathname.includes('organizer') &&
                !url.pathname.includes('exhibitor') &&
                !url.pathname.includes('staff') &&
                !url.pathname.includes('media') && (
                    <p
                        css={css`
                            padding-bottom: 10px;
                        `}
                    >
                        Gadżety można zamawiać do{' '}
                        <strong>26go listopada</strong>. <br />
                        Wysyłka realizowana będzie paczkomatem - koszt 20 zł.
                        <br />
                        Gadżety będą rozsyłane na początku grudnia.
                    </p>
                )}
            {(url.pathname.includes('program') ||
                url.pathname.includes('exhibitor')) && (
                <p
                    css={css`
                        padding-bottom: 10px;
                    `}
                ></p>
            )}
            {(url.pathname.includes('organizer') ||
                url.pathname.includes('media')) && (
                <p
                    css={css`
                        padding-bottom: 10px;
                    `}
                >
                    Gadżety można zamawiać do <strong>27go listopada</strong>.{' '}
                    <br />
                    Wysyłka realizowana będzie paczkomatem - koszt 20 zł.
                    Gadżety będą wysyłane na początku grudnia.
                </p>
            )}

            {url.pathname.includes('staff') && (
                <p
                    css={css`
                        padding-bottom: 10px;
                    `}
                >
                    Drodzy Wolontariusze,
                    <br />
                    Chcielibyśmy Was poinformować, że Imladris jest organizowany
                    w duchu ekonomii daru i idei od fanów dla fanów. Zależy nam
                    na tym, aby zachować te tradycje, opierając się na wzajemnym
                    wsparciu i zaangażowaniu. Z racji tego, że nasze wydarzenie
                    jest inicjatywą non-profit, a główne finansowanie pochodzi z
                    akredytacji, które są niezbędne, aby pokryć koszty
                    organizacji, zdecydowaliśmy się organizować nasz konwent w
                    formie składkowej. W związku z tym nie tylko wszyscy
                    uczestnicy, ale także my, organizatorzy, opłacamy wejściówki
                    i tę samą ideę chcemy propagować wśród wolontariuszy.
                    Rozumiemy, że sytuacje bywają różne, dlatego oferujemy
                    pełną, 100-procentową zniżkę dla osób, które potrzebują
                    takiego wsparcia. Jednocześnie mamy również inne opcje dla
                    tych, którzy mogą wnieść swój wkład. Możecie również dokupić
                    dodatkowe gadżety lub skorzystać z opcji Smoczego Mecenatu,
                    czyli pełnej akredytacji wspierającej, jeśli chcecie wnieść
                    większy wkład w naszą inicjatywę. Dziękujemy za Wasze
                    wsparcie i zaangażowanie!
                    <br />
                    Czekamy na Was na konwencie!
                </p>
            )}
            <p
                css={css`
                    font-size: 16px;
                    text-align: center;
                `}
            >
                {lang === 'pl' ? 'Typ akredytacji:' : 'Tickets type:'}
            </p>
            <fieldset>
                {accreditationTypes.map((accreditationType) => (
                    <React.Fragment key={accreditationType.name}>
                        <Field
                            fieldType="Radio"
                            cssStyles={css`
                                font-size: 18px;
                                font-weight: bold;
                            `}
                            id="accreditationType"
                            name={accreditationType.name}
                            label={
                                accreditationType.title +
                                ' - ' +
                                accreditationType.price +
                                (lang === 'pl' ? ' zł' : ' EUR')
                            }
                            isRequired={false}
                            value={accreditationType.price.toString()}
                            onChange={onAccreditationTypeChange}
                        ></Field>
                        <p
                            dangerouslySetInnerHTML={{
                                __html: accreditationType.description,
                            }}
                        ></p>
                    </React.Fragment>
                ))}
            </fieldset>
        </div>
    );
};

export default SignUpFormAccreditationTypes;
