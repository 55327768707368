import React, {
    useEffect,
    useState,
    createContext,
    ChangeEvent,
    FormEvent,
} from 'react';
import { useParams } from 'react-router-dom';
import { MembershipForm } from '../types';
import { MembershipFormContextProps } from '../index';
import { getMembershipByType } from '../services/membership';
import SignUpFormSection from './SignUpFormSection';
import MembershipLink from './MembershipLink';
import Spinner from '../../Core/components/Spinner';
import { Validator, ValidationErrors } from '../../Core/Form/validators/types';
import { required } from '../../Core/Form/validators/required';
import { email } from '../../Core/Form/validators/email';
// import Field from './Field';
import { submit } from '../../Register/Services/submit';
import { EmailCheck } from '../../Register/Services/submit';
import { appSettings } from '../../AppSettings';

import { useTranslation } from 'react-i18next';

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import SignUpFormFooter from './SignUpFormFooter';
import SignUpFormAccreditationTypes from './SignUpFormAccreditationTypes';
import { phoneNumber } from '../../Core/Form/validators/phoneNumber';

// TODO JK: czy potrzebny jest string[] ?
export type Values = {
    [key: string]: string | string[] | number | MerchOptions;
};

export type MerchOptions = {
    [key: string]: string[] | string | number;
};

const initialValues = {
    Merch: [],
} as Values;

/*
export const FormContext = createContext<FormContextProps>({
    values: {},
    errors: {},
    touched: {},
    currentSectionIndex: 0,
    currentPrice: 0,
});
*/

//TODO JK: ValidationProp czy poprostu Validations i dać to wyzej?
export type ValidationProp = {
    [key: string]: Validator | Validator[];
};

export type Touched = {
    [key: string]: boolean;
};

export type Errors = {
    [key: string]: string[];
};

const emptyForm = {
    name: '',
    title: '',
    membershipAccreditationTypes: [],
} as MembershipForm;

export const FormContext = createContext<MembershipFormContextProps>({
    values: {},
    errors: {},
    touched: {},
    currentSection: 0,
    currentAccreditationType: '',
    currentPrice: 0,
    membershipForm: null,
    sectionsNumber: 0,
    isTermsError: false,
    isAddonError: false,
});

const SignUpForm: React.FC = () => {
    const { t } = useTranslation();

    const lang = appSettings.language;

    const [membershipForm, setMembershipForm] =
        useState<MembershipForm>(emptyForm);
    const { membershipType } = useParams();

    const [values, setValues] = useState<Values>(initialValues);

    const [isCapCommentError, setIsCapCommentError] = useState<boolean>(false);
    const [isLongSleeveCommentError, setIsLongSleeveCommentError] =
        useState<boolean>(false);
    const [isTermsError, setIsTermsError] = useState<boolean>(false);
    const [isAddonError, setIsAddonError] = useState<boolean>(false);
    const [errors, setErrors] = useState<ValidationErrors>({});
    const [hasErrors, setHasErrors] = useState<boolean>(false);
    const [submitInProgress, setSubmitInProgress] = useState<boolean>(false);
    const [touched, setTouched] = useState<Touched>({});

    const [lastBagCount, setLastBagCount] = useState<number>(1);
    const [lastCapCount, setLastCapCount] = useState<number>(1);
    const [lastLongSleeveCount, setLastLongSleeveCount] = useState<number>(1);
    const [registrationSucceeded, setRegistrationSucceeded] =
        useState<boolean>(false);
    const [loadInProgress, setLoadInProgress] = useState(false);

    const [currentPrice, setCurrentPrice] = useState<number>(0);
    const [currentSection, setCurrentSection] = useState<number>(0);
    const [currentAccreditationType, setCurrentAccreditationType] =
        useState<string>('');
    const [sectionsNumber, setSectionsNumber] = useState<number>(0);

    const registrationEnded = false;

    const validationRules = {
        Email: [{ validationAction: email }, { validationAction: required }],
        FirstName: [{ validationAction: required }],
        LastName: [{ validationAction: required }],
        PhoneNumber: [{ validationAction: phoneNumber }],
    } as ValidationProp;

    const validate = (fieldName: string, isForm: boolean = false): string[] => {
        if (
            !validationRules &&
            membershipType !== 'Exhibitor' &&
            (fieldName === 'NickName' || fieldName !== 'ArriveTime')
        ) {
            return [];
        }
        if (
            fieldName !== 'CapComment' &&
            fieldName !== 'LongSleeveComment' &&
            fieldName !== 'NickName' &&
            fieldName !== 'ArriveTime' &&
            !validationRules[fieldName]
        ) {
            return [];
        }

        const fieldErrors: string[] = [];

        if (!isForm && fieldName === 'Email') {
            validateEmail();
        }

        if (
            !isForm &&
            fieldName === 'NickName' &&
            membershipType === 'exhibitor'
        ) {
            const nickNameRequired = required(values['NickName']);
            if (nickNameRequired) {
                fieldErrors.push(nickNameRequired);
            }
        }

        if (
            !isForm &&
            fieldName === 'PhoneNumber' &&
            currentAccreditationType.includes('Addons')
        ) {
            const phoneNumberRequired = required(values['PhoneNumber']);
            if (phoneNumberRequired) {
                fieldErrors.push(phoneNumberRequired);
            }
        }

        if (
            !isForm &&
            fieldName === 'ArriveTime' &&
            currentAccreditationType.includes('Addons')
        ) {
            const addOnsRequired = required(values['ArriveTime']);
            if (addOnsRequired) {
                fieldErrors.push(addOnsRequired);
            }
        }

        if (
            fieldName === 'CapComment' &&
            values['cap'] &&
            values['cap'] === 1
        ) {
            const addTS = required(values['CapComment']);
            if (addTS) {
                fieldErrors.push(addTS);
                setIsCapCommentError(true);
            } else {
                setIsCapCommentError(false);
            }
        }

        if (
            fieldName === 'LongSleeveComment' &&
            values['longSleeve'] &&
            values['longSleeve'] === 1
        ) {
            const addTS = required(values['LongSleeveComment']);
            if (addTS) {
                fieldErrors.push(addTS);
                setIsLongSleeveCommentError(true);
            } else {
                setIsLongSleeveCommentError(false);
            }
        }

        if (validationRules && validationRules[fieldName]) {
            const rules = Array.isArray(validationRules[fieldName])
                ? (validationRules[fieldName] as Validator[])
                : ([validationRules[fieldName]] as Validator[]);

            rules.forEach((rule) => {
                const error = rule.validationAction(
                    values[fieldName],
                    rule.arg,
                );
                if (error) {
                    fieldErrors.push(error);
                }
            });
        }

        const newErrors = { ...errors, [fieldName]: fieldErrors };
        setErrors(newErrors);
        return fieldErrors;
    };

    const validateForm = () => {
        let newErrors: Errors = {};
        let haveError: boolean = false;

        if (validationRules) {
            Object.keys(validationRules).forEach(async (fieldName) => {
                newErrors[fieldName] = validate(fieldName, true);
                if (newErrors[fieldName].length > 0) {
                    haveError = true;
                }
            });

            const merchErrors = validateMerch();

            if (Object.keys(merchErrors).length > 0) {
                newErrors = { ...newErrors, ...merchErrors };
                haveError = true;
            }

            if (values['cap'] && values['cap'] === 1) {
                newErrors['CapComment'] = validate('CapComment');
                if (newErrors['CapComment'].length > 0) {
                    haveError = true;
                }
            }

            if (values['longSleeve'] && values['longSleeve'] === 1) {
                newErrors['LongSleeveComment'] = validate('LongSleeveComment');
                if (newErrors['LongSleeveComment'].length > 0) {
                    haveError = true;
                }
            }

            if (currentAccreditationType.includes('Addons')) {
                newErrors['ArriveTime'] = validate('ArriveTime');
                if (newErrors['ArriveTime'].length > 0) {
                    haveError = true;
                }
            }

            setIsAddonError(false);
            if (
                currentAccreditationType.includes('Addons') ||
                currentAccreditationType.includes('FreeAdd')
            ) {
                newErrors['PhoneNumber'] = validate('PhoneNumber');
                if (newErrors['PhoneNumber'].length > 0) {
                    haveError = true;
                }

                const merch = values['Merch'] as MerchOptions;
                const tshirt = merch.TShirt;
                const blanketBig = merch.BlanketBig;
                const hoodie = merch.Hoodie;

                if (!tshirt && !blanketBig && !hoodie) {
                    setIsAddonError(true);
                    haveError = true;
                }
            }

            if (membershipType === 'exhibitor') {
                newErrors['NickName'] = validate('NickName');
                if (newErrors['NickName'].length > 0) {
                    haveError = true;
                }
            }

            setIsTermsError(false);
            if (!values['Terms']) {
                setIsTermsError(true);
                haveError = true;
            }
        }

        setErrors(newErrors);
        return !haveError;
    };

    //TODO JK: jak to zabezpieczyć żeby było odporne - prefix?

    const validateMerch = () => {
        const merchErrors: Errors = {};
        const merch = values['Merch'] as MerchOptions;

        Object.keys(merch)
            .filter((key) => !key.includes('Options') && !key.includes('Count'))
            .forEach((merchOptionsKey: string) => {
                if (merch[merchOptionsKey]) {
                    const merchOptions = merch[
                        `${merchOptionsKey}Options`
                    ] as string[];
                    if (merchOptions) {
                        merchOptions.forEach((option, index) => {
                            const requiredError = required(option);
                            if (requiredError) {
                                merchErrors[
                                    `${merchOptionsKey}_option_${index}`
                                ] = [requiredError];
                            }
                        });
                    }
                }
            });

        return merchErrors;
    };

    const onBlurTextArea = (e: ChangeEvent<HTMLTextAreaElement>): void => {
        validate(e.target.name);
    };

    const validateEmail = async (): Promise<boolean> => {
        const fieldErrors = [];
        if (
            currentAccreditationType.includes('Teenager') &&
            values['Email'] &&
            values['Email'] !== ''
        ) {
            if (email(values['Email']) === '') {
                try {
                    await EmailCheck(values['Email'] as string);
                } catch (error) {
                    fieldErrors.push(
                        'Brak opiekuna z takim adresem e-mail. Aby zarejestrować uczestnika do lat 12, należy najpierw zarejestrować opiekuna.',
                    );

                    const field = 'Email';

                    const newErrors = { ...errors, [field]: fieldErrors };
                    setErrors(newErrors);

                    return false;
                }
            }
        }

        return true;
    };

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const isFormValid = validateForm();

        let emailCheck = true;

        if (currentAccreditationType.includes('Teenager')) {
            emailCheck = await validateEmail();
        }

        //in progress
        values['accreditationType'] = currentAccreditationType;
        values['language'] = appSettings.language;

        if (isFormValid && emailCheck) {
            // && emailCheck) {
            setHasErrors(false);
            setSubmitInProgress(true);
            await submit(values);
            setSubmitInProgress(false);
            setRegistrationSucceeded(true);
        } else {
            setHasErrors(true);
        }
    };

    const onAddOnChange = (e: ChangeEvent<HTMLInputElement>) => {
        setCurrentPrice(
            e.target.checked
                ? currentPrice + Number(e.target.value)
                : currentPrice - Number(e.target.value),
        );

        setValues({
            ...values,
            [e.target.name]: e.target.checked ? 1 : 0,
        });

        if (e.target.name === 'tShirt') {
            let newPrice;
            if (e.target.checked) {
                newPrice =
                    currentPrice + 1 * calculateAddOnsPrice(e.target.name);
            } else {
                newPrice =
                    currentPrice - 1 * calculateAddOnsPrice(e.target.name);
            }
            setCurrentPrice(newPrice);
        }

        if (e.target.name === 'bag') {
            let newPrice;
            if (e.target.checked) {
                newPrice =
                    currentPrice +
                    lastBagCount * calculateAddOnsPrice(e.target.name);
            } else {
                newPrice =
                    currentPrice -
                    lastBagCount * calculateAddOnsPrice(e.target.name);
            }
            setCurrentPrice(newPrice);
        }

        if (e.target.name === 'cap') {
            let newPrice;
            if (e.target.checked) {
                newPrice =
                    currentPrice +
                    lastCapCount * calculateAddOnsPrice(e.target.name);
            } else {
                newPrice =
                    currentPrice -
                    lastCapCount * calculateAddOnsPrice(e.target.name);
            }
            setCurrentPrice(newPrice);
        }

        if (e.target.name === 'longSleeve') {
            let newPrice;
            if (e.target.checked) {
                newPrice =
                    currentPrice +
                    lastLongSleeveCount * calculateAddOnsPrice(e.target.name);
            } else {
                newPrice =
                    currentPrice -
                    lastLongSleeveCount * calculateAddOnsPrice(e.target.name);
            }
            setCurrentPrice(newPrice);
        }
    };

    const calculateAddOnsPrice = (addOnsName: string): number => {
        if (addOnsName === 'tShirt') {
            return lang === 'pl' ? 79 : 18;
        }

        if (addOnsName === 'longSleeve') {
            return lang === 'pl' ? 129 : 29;
        }

        if (addOnsName === 'bag') {
            return lang === 'pl' ? 39 : 10;
        }

        if (addOnsName === 'cap') {
            return lang === 'pl' ? 69 : 16;
        }

        return 0;
    };

    const onCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
        setValues({
            ...values,
            [e.target.name]: e.target.checked ? 1 : 0,
        });
    };

    const onChange = (e: ChangeEvent<HTMLSelectElement>) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value,
        });
    };

    const onTextAreaChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value,
        });
    };

    const onInputValueChange = (e: ChangeEvent<HTMLInputElement>) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value,
        });
    };

    const onTShirtCountChange = (e: ChangeEvent<HTMLInputElement>) => {
        let value = e.target.value;

        if (!value) {
            value = '1';
        } else {
            value = value.substring(value.length - 1, value.length);
        }

        if (Number(value) < 1) {
            value = '1';
        }

        if (Number(value) > 9) {
            value = '9';
        }

        const newTShirtCount = Number(value);

        setValues({
            ...values,
            [e.target.name]: newTShirtCount,
        });

        let priceWithNoTShirt =
            currentPrice - 1 * calculateAddOnsPrice('tShirt');
        const newPrice =
            priceWithNoTShirt + newTShirtCount * calculateAddOnsPrice('tShirt');

        setCurrentPrice(newPrice);
    };

    const onBagCountChange = (e: ChangeEvent<HTMLInputElement>) => {
        let value = e.target.value;

        if (!value) {
            value = '1';
        } else {
            value = value.substring(value.length - 1, value.length);
        }

        if (Number(value) < 1) {
            value = '1';
        }

        if (Number(value) > 9) {
            value = '9';
        }

        const newBagCount = Number(value);

        setValues({
            ...values,
            [e.target.name]: newBagCount,
        });

        let priceWithNoBag =
            currentPrice - lastBagCount * calculateAddOnsPrice('bag');
        const newPrice =
            priceWithNoBag + newBagCount * calculateAddOnsPrice('bag');

        setCurrentPrice(newPrice);
        setLastBagCount(newBagCount);
    };

    const onCapCountChange = (e: ChangeEvent<HTMLInputElement>) => {
        let value = e.target.value;

        if (!value) {
            value = '1';
        } else {
            value = value.substring(value.length - 1, value.length);
        }

        if (Number(value) < 1) {
            value = '1';
        }

        if (Number(value) > 9) {
            value = '9';
        }

        const newCapCount = Number(value);

        setValues({
            ...values,
            [e.target.name]: newCapCount,
        });

        let priceWithNoCap =
            currentPrice - lastCapCount * calculateAddOnsPrice('cap');
        const newPrice =
            priceWithNoCap + newCapCount * calculateAddOnsPrice('cap');

        setCurrentPrice(newPrice);
        setLastCapCount(newCapCount);
    };

    const onLongSleeveCountChange = (e: ChangeEvent<HTMLInputElement>) => {
        let value = e.target.value;

        if (!value) {
            value = '1';
        } else {
            value = value.substring(value.length - 1, value.length);
        }

        if (Number(value) < 1) {
            value = '1';
        }

        if (Number(value) > 9) {
            value = '9';
        }

        const newLongSleeveCount = Number(value);

        setValues({
            ...values,
            [e.target.name]: newLongSleeveCount,
        });

        let priceWithNoLongSleeve =
            currentPrice -
            lastLongSleeveCount * calculateAddOnsPrice('longSleeve');
        const newPrice =
            priceWithNoLongSleeve +
            newLongSleeveCount * calculateAddOnsPrice('longSleeve');

        setCurrentPrice(newPrice);
        setLastLongSleeveCount(newLongSleeveCount);
    };

    useEffect(() => {
        const doGetMembershipForm = async () => {
            setLoadInProgress(true);

            const membershipFormFromRest = await getMembershipByType(
                membershipType,
            );
            // setSignUpList(memberships);
            // console.log(membershipForm);
            setLoadInProgress(false);
            setMembershipForm(membershipFormFromRest);
        };

        doGetMembershipForm();
    }, [membershipType]);

    return (
        <FormContext.Provider
            value={{
                values,
                setValues,
                setValue: (fieldName: string, value: string | number) => {
                    setValues({ ...values, [fieldName]: value });
                },
                errors,
                setErrors: (errors: ValidationErrors) => {
                    setErrors(errors);
                },
                validate,
                touched,
                setTouched: (fieldName: string) => {
                    setTouched({ ...touched, [fieldName]: true });
                },
                currentSection,
                setCurrentSection: (newIndex: number) => {
                    setCurrentSection(newIndex);
                },
                currentAccreditationType,
                currentPrice,
                setCurrentPrice: (priceToAdd: number) => {
                    setCurrentPrice(priceToAdd);
                },
                setCurrentAccreditationType: (
                    currentAccreditationType: string,
                ) => {
                    setCurrentAccreditationType(currentAccreditationType);
                },
                membershipForm,
                sectionsNumber,
                setSectionsNumber: (newSectionsNumber: number) => {
                    setSectionsNumber(newSectionsNumber);
                },
                isTermsError,
                setIsTermsError: (newIsTermsError: boolean) => {
                    setIsTermsError(newIsTermsError);
                },
                isAddonError,
                setIsAddonError: (newIsAddonError: boolean) => {
                    setIsAddonError(newIsAddonError);
                },
            }}
        >
            <div
                style={{
                    display:
                        (registrationSucceeded && !loadInProgress) ||
                        registrationEnded
                            ? 'none'
                            : 'block',
                }}
            >
                <div
                    css={css`
                        margin-bottom: 30px;
                        text-align: center;
                        font-size: 18px;
                    `}
                >
                    <p>Imladris XXII</p>
                </div>
                <div
                    css={css`
                        @media (min-width: 550px) {
                            width: 550px;
                        }
                        margin-left: auto;
                        margin-right: auto;
                    `}
                >
                    <form noValidate={true} onSubmit={handleSubmit}>
                        <div
                            css={css`
                                font-size: 16px;
                                text-align: center;
                            `}
                        ></div>
                        <SignUpFormAccreditationTypes
                            accreditationTypes={
                                membershipForm.membershipAccreditationTypes
                            }
                        />
                        {membershipForm.membershipAccreditationTypes.map(
                            (accreditationType) =>
                                accreditationType.sections.map(
                                    (section, index) => (
                                        <SignUpFormSection
                                            section={section}
                                            sectionNumber={index + 1}
                                            accreditationType={
                                                accreditationType.name
                                            }
                                            key={index}
                                        />
                                    ),
                                ),
                        )}
                        <div
                            style={{
                                display:
                                    currentSection === 3 &&
                                    (currentAccreditationType === 'Vip' ||
                                        currentAccreditationType ===
                                            'Vip-program' ||
                                        currentAccreditationType ===
                                            'Vip-guest' ||
                                        currentAccreditationType ===
                                            'Vip-exhibitor' ||
                                        currentAccreditationType ===
                                            'Vip-media' ||
                                        currentAccreditationType.startsWith(
                                            'Vip',
                                        ))
                                        ? 'block'
                                        : 'none',
                            }}
                        >
                            <input
                                type="checkbox"
                                id="Anonymous"
                                name="Anonymous"
                                onChange={onCheckboxChange}
                                checked={values['Anonymous'] === 1}
                            />
                            <label
                                css={css`
                                    font-size: 16px;
                                    margin-left: 10px;
                                    font-weight: bold;
                                `}
                                htmlFor="1"
                            >
                                Chcę pozostać anonimowa/anonimowy
                            </label>
                            <p>
                                W podziękowaniu za dodatkowe wsparcie konwentu,
                                każdy Smoczy Mecenat otrzymuje imienne
                                podziękowania na stronie www oraz w informatorze
                                (PDF). Jeśli z jakichkolwiek powodów nie chcesz
                                być uwzględniona/uwzględniony na liście Smoczych
                                Mecenatów, zaznacz tę opcję.
                            </p>
                        </div>
                        {hasErrors && (
                            <div>
                                <div
                                    css={css`
                                        text-align: center;
                                        font-weight: bold;
                                        font-size: 16px;
                                        color: red;
                                        margin-bottom: 20px;
                                    `}
                                >
                                    {lang === 'pl'
                                        ? 'Formularz zawiera błędy'
                                        : 'The form contains errors'}
                                </div>
                            </div>
                        )}
                        <SignUpFormFooter />
                    </form>
                    <p
                        css={css`
                            font-size: 12px;
                            margin-top: 25px;
                            text-align: center;
                        `}
                    >
                        {t('signup.form.ticket-help')} przedplaty@imladris.pl
                    </p>
                    <div
                        css={css`
                            margin-top: 20px;
                        `}
                    >
                        <a href="/signupTerms" target="_blank">
                            Regulamin serwisu
                        </a>
                        <span
                            css={css`
                                float: right;
                            `}
                        >
                            Płatności realizowane przez:
                        </span>
                    </div>
                    <div
                        css={css`
                            margin-top: 10px;
                        `}
                    >
                        <a href="/privacyPolicy" target="_blank">
                            Polityka Prywatności
                        </a>
                        <img
                            css={css`
                                float: right;
                                padding-right: 65px;
                            `}
                            alt=""
                            src="/Przelewy24_logo.png"
                            width={50}
                            height={18}
                        ></img>
                    </div>
                    <div
                        style={{
                            display: submitInProgress ? 'block' : 'none',
                        }}
                    >
                        <div
                            css={css`
                                position: fixed;
                                top: 0;
                                left: 0;
                                z-index: 111;
                                background-color: rgba(0, 0, 0, 0.35);
                                width: 100%;
                                height: 100%;
                            `}
                        >
                            <div
                                css={css`
                                    padding: 20px;
                                    position: absolute;
                                    background-color: #fff;
                                    width: 400px;
                                    height: 180px;
                                    top: 50%;
                                    left: 50%;
                                    transform: translate(-50%, -50%);
                                    opacity: 1;
                                    box-shadow: 0 0 5px #333;
                                    text-align: center;
                                    border-radius: 6px;
                                `}
                            >
                                <div
                                    css={css`
                                        padding-bottom: 20px;
                                    `}
                                >
                                    <span>
                                        {lang === 'pl'
                                            ? 'Zapisywanie zgłoszenia...'
                                            : 'Saving your request...'}
                                    </span>
                                </div>
                                <div>
                                    <img
                                        src="/icons/spinner.svg"
                                        width="100"
                                        height="100"
                                        alt="spinner"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        style={{
                            display: loadInProgress ? 'block' : 'none',
                        }}
                    >
                        <div
                            css={css`
                                position: fixed;
                                top: 0;
                                left: 0;
                                z-index: 111;
                                background-color: rgba(0, 0, 0, 0.35);
                                width: 100%;
                                height: 100%;
                            `}
                        >
                            <div
                                css={css`
                                    padding: 20px;
                                    position: absolute;
                                    background-color: #fff;
                                    width: 400px;
                                    height: 180px;
                                    top: 50%;
                                    left: 50%;
                                    transform: translate(-50%, -50%);
                                    opacity: 1;
                                    box-shadow: 0 0 5px #333;
                                    text-align: center;
                                    border-radius: 6px;
                                `}
                            >
                                <div
                                    css={css`
                                        padding-bottom: 20px;
                                    `}
                                >
                                    <span>Wczytywanie danych...</span>
                                </div>
                                <div>
                                    <img
                                        src="/icons/spinner.svg"
                                        width="100"
                                        height="100"
                                        alt="spinner"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {registrationSucceeded && (
                <div
                    css={css`
                        min-height: 300px;
                        margin-top: 25px;
                    `}
                >
                    <div
                        css={css`
                            text-align: center;
                            font-weight: bold;
                            font-size: 16px;
                            margin-bottom: 30px;
                        `}
                    >
                        Trwa przekierownaie do systemu płatności...
                    </div>
                </div>
            )}
        </FormContext.Provider>
    );
};

export default SignUpForm;
